import React, { useState, useEffect } from 'react';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/AuthContext.js';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

function DocumentacionProyectos(slug,{darkMode}) {
  const [data, setData] = useState(null);
  const [estados, setEstados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const endpoint = 'getDocProyect/'+slug.slug;
  //console.log(location.pathname)  

  const {authData,logout} = useAuth();
  const navigate = useNavigate();

  const [registroSeleccionado, setRegistroSeleccionado] = useState(null);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [openAdd, setopenAdd] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [shouldReload , setShouldReload] = useState('init');

  const token = authData ? authData.token:null;
  const user_id = authData ? authData.user_id.toString():null;
  const rolUser = authData ? authData.rol:null;

  //console.log(location.pathname)  
  useEffect(() => {
    if (shouldReload === 'init' || shouldReload === 'reload'|| shouldReload === 'reloadEdit' || shouldReload === 'reloadDelete') {
      if(rolUser === 'admin_javi'){
        setisAdmin(true);
      }
      setShouldReload('noReload');
      setopenAdd(false)
      setModalEditar(false);
      setModalEliminar(false);
      setLoading(true);
      const dataToSend = { user_id };
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        const data = await response.json();
        console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
        setData(data);
        setEstados([...new Set(data.map(tarea => tarea.Estado))]);
        setLoading(false);
      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoading(false);
        //navigate('/j/notFound');
        throw error;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify(),shouldReload]);

  const renderButtonAddAdmin = () => {
    if(isAdmin){
      return (
        <div className="container">
          <div className="row">
            <button className="btn btn-primary" onClick={() => setopenAdd(!openAdd)}><i className="bi bi-plus-circle"></i> Agregar Documentación</button>
          </div>
          <form className="row g-3 mt-3" style={{display: openAdd ? '':'none'}} onSubmit={handleSubmit}>
          <div className="col-md-12">
              <label htmlFor="nombre" className="form-label">Titulo Documento</label>
              <input type="text" className="form-control" id="nombre" name="nombre" placeholder="Titulo Documento" />
            </div>
            <div className="col-md-12">
              <label htmlFor="url" className="form-label">URL</label>
              <input type="text" className="form-control" id="url" name="url" placeholder="URL Documento" />
            </div>
            <div className="col-md-12">
              <label htmlFor="description" className="form-label">Descripción</label>
              <textarea className="form-control" id="description" name="description" placeholder="Descripción Documento" >
              </textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary" disabled={loadingx}>
               {loadingx ? 'Enviando...' : 'Registrar'}
              </button>            
              </div>
          </form>
        </div>
      )
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    const dataForm = new FormData(e.target);
    const type = 'documentacion';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      nombre: dataForm.get("nombre"),
      url: dataForm.get("url"),
      description: dataForm.get("description"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.nombre === '' || dataToSend.url === '' || dataToSend.description === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/crearProyect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reload');
      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    const dataForm = new FormData(e.target);
    const type = 'documentacion';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      nombre: dataForm.get("nombre"),
      url: dataForm.get("url"),
      description: dataForm.get("description"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.nombre === '' || dataToSend.url === '' || dataToSend.description === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/editarProyect/${registroSeleccionado}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadEdit');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleDelete = async () => {
    //setLoading(true);
    const type = 'documentacion';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      user_id,
      token,
      type,
      idProyecto
    };
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/eliminarProyect/${registroSeleccionado}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadDelete');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }  
 

  function openModalEdit(id) {
    console.log('Editar: ', id);
    setModalEditar(true);
    setModalEliminar(false);
    setRegistroSeleccionado(id);
  }

  function closeModalEdit(){
    setModalEditar(false);
  }

  function openModalDelete(id) {
    console.log('Editar: ', id);
    setModalEditar(false);
    setModalEliminar(true);
    setRegistroSeleccionado(id);
  }

  if (loading) return <div className="loadingLeft"><LoadingComponent darkMode={darkMode?'dark':''} text="Cargando Documentación..........." /></div>;
  if (error) return <p>Ocurrió un error: {error}</p>;

  const documentacion = data ? data.find((tarea) => tarea.id === registroSeleccionado) : null;

  if (modalEditar){
    console.log(data)
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={closeModalEdit}>&times;</span>
          <h5>Editar Registro</h5>
          {documentacion && (
          <form className="row g-3 mt-3" onSubmit={handleSaveEdit}>
          <div className="col-md-12">
              <label htmlFor="nombre" className="form-label">Titulo Documento</label>
              <input defaultValue={documentacion.nombre} type="text" className="form-control" id="nombre" name="nombre" placeholder="Titulo Documento" />
            </div>
            <div className="col-md-12">
              <label htmlFor="url" className="form-label">URL</label>
              <input defaultValue={documentacion.url} type="text" className="form-control" id="url" name="url" placeholder="URL Documento" />
            </div>
            <div className="col-md-12">
              <label htmlFor="description" className="form-label">Descripción</label>
              <textarea defaultValue={documentacion.description} className="form-control" id="description" name="description" placeholder="Descripción Documento" >
              </textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary" disabled={loadingx}>
               {loadingx ? 'Enviando...' : 'Guardar Cambios'}
              </button>            
              </div>
          </form>
          )}
        </div>
        {data && isAdmin && (
          <>
            <div className="modal-footer">
             
              <button className="btn btn-danger btn-sm" onClick={() => openModalDelete(data.id)}>Eliminar</button>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }


  if (modalEliminar){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={() => setModalEliminar(false)}>&times;</span>
          <h5>Eliminar Registro</h5>
          {documentacion && (
            <div className='mt-3'>
              <p className='mb-1'><strong>Titulo:</strong> {documentacion.nombre}</p>
              <p className='mb-1'><strong>Url:</strong> {documentacion.detalle}</p>
              <p className='mb-1'><strong>Descripcion:</strong> {documentacion.description}</p>
              {/* Agrega aquí más detalles según necesites */}
            </div>
          )}
        </div>
        {data && isAdmin && (
          <>
            <div className="modal-footer">
              <button className="btn btn-danger btn-sm" onClick={() => handleDelete()} disabled={loadingx}>
                {loadingx ? 'Eliminando...' : 'Confirmar Eliminar'}
              </button>
             
              <button className="btn btn-info btn-sm" onClick={() => setModalEliminar(false)} disabled={loadingx}>
                Cancelar
                </button>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }

  return (
    <div className="container mt-4">
      <h4 className=''>Documentación del Proyecto</h4>
      <p className="text-muted">Acceda a todos los documentos relacionados con el proyecto aquí.</p>
      <div className="col-12 col-md-6 mb-3">
        {renderButtonAddAdmin()}
      </div>
      {data && data.length === 0 ? (
        <div className="alert alert-info" role="alert">
          No hay documentos disponibles.
        </div>
      ) : (
        <div className="row">
          {data && data.map((documento, index) => (
            <div className="col-md-4 mb-3" key={index}>
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="card-title">{documento.nombre}</h5>
                  <p className="card-text">{documento.description}</p>
                </div>
                <div className="card-footer">
                  <a href={documento.url} className="btn btn-info" target="_blank" rel="noopener noreferrer">Ver Documento</a>
                </div>
                {isAdmin && (
                <div className="modal-footer" >
                  <button className="btn btn-primary btn-sm" onClick={() => openModalEdit(documento.id)}>Editar</button>
                  <button className="btn btn-danger btn-sm" onClick={() => openModalDelete(documento.id)}>Eliminar</button>
                </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default DocumentacionProyectos;