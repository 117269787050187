import React, { useState } from 'react';
import TypingEffect from '../TypingEffect/TypingEffect.js';
import TareasProyectos from '../tareasComponent/TareasProyectos.js';
import CronogramaProyecto from '../tareasComponent/CronogramaProyecto.js';
import HistorialProyectos from '../tareasComponent/HistorialProyecto.js';
import DocumentacionProyectos from '../tareasComponent/DocumentacionProyecto.js';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const ProyectCard = ({ data }) => {
  const [typeOption, setTypeOption] = useState('historial');
  const [activeButton, setActiveButton] = useState('historial');
  function setType(type) {
    setTypeOption(type);
    setActiveButton(type);
  }


  const renderInputs = () => {
    switch (typeOption) {
      case 'tareas':
        return (<><TareasProyectos slug={data.id} /></>);
      case 'cronograma':
        return (<> <CronogramaProyecto slug={data.id} /></>);
      case 'historial':
        return ( <> <HistorialProyectos slug={data.id} /> </>);
      case 'documentacion':
        return (<> <DocumentacionProyectos slug={data.id} /> </>);
      default:
        return <p>Seleccione una opción para continuar</p>;
    }
  };

  if (!data) return null;

  const { id, Nombre, Descripcion, fechaActualiza, fechaInicio, urlTest } = data;
  const urlFinal = urlTest.indexOf('http') === -1 ? 'https://javimarambio.cl/j/' + urlTest : urlTest;
  return (
    <article className="container mb-5" id="proyectDevCont">
      <div className="row">
        <div className="col-12 col-md-6">
          <h2 className="display-4 mb-3"><TypingEffect text={Nombre} /></h2>
          <div className="mb-3">{Descripcion}</div>
        </div>
      </div>
      <p className="text-muted mb-0">
        <span><strong>Inicio Proyecto:</strong> {format(fechaInicio, "dd MMMM yyyy", { locale: es })}</span>
      </p>
      <p className="text-muted mt-1">
        <span><strong>Actualización:</strong> {format(fechaActualiza, "dd MMMM HH:mm", { locale: es })} hrs.</span>
      </p>
      <div id="buttonProyect" className='mb-3'>
        <button type="button" className={`btn ${activeButton === 'historial' ? 'btn-primary' : 'btn-outline-primary'} btn-sm`} onClick={() => setType('historial')}>
          Historial
        </button>
        <button type="button" className={`btn ${activeButton === 'tareas' ? 'btn-primary' : 'btn-outline-primary'} btn-sm`} onClick={() => setType('tareas')}>
          Tareas
        </button>
        <button type="button" className={`btn ${activeButton === 'cronograma' ? 'btn-primary' : 'btn-outline-primary'} btn-sm`} onClick={() => setType('cronograma')}>
          Calendario
        </button>
        <button type="button" className={`btn ${activeButton === 'documentacion' ? 'btn-primary' : 'btn-outline-primary'} btn-sm`} onClick={() => setType('documentacion')}>
          Documentación
        </button>
        <a rel="noopener" href={urlFinal} type="button" className="btn btn-outline-primary btn-sm" target="_blank">Ver Sitio Web</a>
      </div>
      {renderInputs()}
    </article>
  );
};

export default ProyectCard;