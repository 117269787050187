import React from 'react';
import TypingEffect from '../TypingEffect/TypingEffect.js';
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";

const ArticleCard = ({ article,urlShare }) => {
    if (!article) return null; // Asegúrate de que el artículo esté presente
  
    const { title, description, img, cat, fecha_publica, author, url, detalles } = article;
    const urlPrincipal = 'https://javimarambio.cl'
    const shareUrl = urlPrincipal+urlShare;
    //console.log(shareUrl)
    
    return (
      <article className="container">
      <h1 className="display-4 mb-3"><TypingEffect text={title} /></h1>
      <p className="text-muted">
        <span className="badge bg-secondary me-2">{cat}</span>
        <span>Publicado el: {fecha_publica}</span>
        <span className="ms-3">Por: {author}</span>
      </p>
      <div>
      <FacebookShareButton
url={shareUrl}
quote={'Title or jo bhi aapko likhna ho'}
hashtag={'#portfolio...'}
>
<FacebookIcon size={40} round={true} />
</FacebookShareButton>
<WhatsappShareButton
url={shareUrl}
quote={'Title or jo bhi aapko likhna ho'}
hashtag={'#portfolio...'}
>
<WhatsappIcon size={40} round={true} />
</WhatsappShareButton>
</div>
      <img src={img} alt={title} className="img-fluid mb-3" />
      <div className="mb-3">{description}</div>
      <div dangerouslySetInnerHTML={{ __html: detalles }} className="article-content"></div>
    </article>
    );
  };

export default ArticleCard;