import React, { useState, useEffect } from 'react';
import { format, set } from 'date-fns';
import { es } from 'date-fns/locale';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/AuthContext.js';
import GetCliente from './GetClient.js';

function ClientesList(slug,{darkMode }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState(null);
  const [userSelect, setUserSelect] = useState(null);

  const endpoint = 'client';
  const {authData,logout} = useAuth();
  const navigate = useNavigate();

  const token = authData ? authData.token:null;
  const user_id = authData ? authData.user_id.toString():null;
  const user_id_get = slug.slug !== '' ? slug.slug:'';

  //console.log(location.pathname)  
  useEffect(() => {
    setLoading(true);
    const dataToSend = { user_id, user_id_get };
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        const data = await response.json();
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
        setData(data);
        setLoading(false);
      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoading(false);
        //navigate('/j/notFound');
        throw error;
      });
  }, [endpoint, JSON.stringify()]);


  function openModal(id) {
    setRegistroSeleccionado(id);
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  function getClient(id){
    //console.log(id);
    setUserSelect(id);
  }


  if (loading)
  return <div className="loadingLeft"><LoadingComponent darkMode={darkMode?'dark':''} skipAnimateText={true} text="Cargando Clientes..........." /></div>;
  
  if (error) 
  return <p>Ocurrió un error: {error}</p>;
  
  if (userSelect){
  return (
    <>
        <GetCliente slug={userSelect} darkMode={darkMode}/>
    </>
  )};

 

  const tarea = data ? data.find(tarea => tarea.ID === registroSeleccionado):null;
  if (modalVisible){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <h5>Detalle Registro</h5>
          {tarea && (
            <div className='mt-3'>
              <p><strong>Nombre:</strong> {tarea.data.display_name}</p>
                  <p><strong>User:</strong> {tarea.data.user_nicename}</p>
                  {/* Agrega aquí más detalles según necesites */}
                  <button className="btn btn-primary btn-sm" onClick={() => getClient(tarea.ID)}>Ver Cliente</button>
              {/* Agrega aquí más detalles según necesites */}
            </div>
          )}
        </div>
        </div>
      </div>
    );
  }



  return (
    <div className="container mt-4">
      <h4 className=''>Clientes</h4>      
      {data && data.length === 0 ? (
        <div className="alert alert-info" role="alert">
          No hay clientes
        </div>
      ) : (
        <div className="accordion col-12 col-md-6" id="historialAccordion">
          {data && data.map((registro, index) => (
            <div className="accordion-item" key={registro.ID}>
              <h2 className="accordion-header" id={`heading${index}`}>
                <button className="accordion-button" onClick={() => openModal(registro.ID)} type="button">
                  {registro.data.display_name}
                </button>
              </h2>
              <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#historialAccordion">
                <div className="accordion-body">
                  <p><strong>Nombre:</strong> {registro.data.display_name}</p>
                  <p><strong>User:</strong> {registro.data.user_nicename}</p>
                  {/* Agrega aquí más detalles según necesites */}
                  <button className="btn btn-primary btn-sm" onClick={() => openModal(registro.ID)}>Ver Más</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  );
}

export default ClientesList;
