import React, { useState, useEffect } from 'react';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/AuthContext.js';

function CronogramaProyecto(slug,{darkMode}) {
    const [datax, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [tareaSeleccionada, setTareaSeleccionada] = useState(null);
    const endpoint = 'getTareas/'+slug.slug;

    const {authData,logout} = useAuth();
    const navigate = useNavigate();
  
    const token = authData ? authData.token:null;
    const user_id = authData ? authData.user_id.toString():null;


    useEffect(() => {
      setLoading(true);
      const dataToSend = { user_id };
      fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(dataToSend)
        }).then(async (response) => {
          const data = await response.json();
          if(response.status === 403){
            logout();
            navigate('/j/login');
            return;
          }
          if (!response.ok) {
            throw new Error(data.message || 'Error en la solicitud');
          }
          setData(
            data
            ?.filter(tarea => tarea.Estado === 'Pendientes' && tarea.addCalendar === '1')
            .map(tarea => ({ 
              id:tarea.id, 
              title: tarea.titulo, 
              fechaEstimada: tarea.fechaEstimada,
              date: new Date(tarea.fechaEstimada)
            })));
          setLoading(false);
        }).catch(error => {
          console.error("Error en fetchData: ", error);
          setError(error.message);
          setLoading(false);
          //navigate('/j/notFound');
          throw error;
        });
    }, [endpoint, JSON.stringify()]);

    /*const esMovil = () => {
      return /Mobi|Android/i.test(navigator.userAgent);
    };
    const vistaPredeterminada = esMovil() ? "week" : "month";*/

    const manejarClickFecha = (tarea) => {
      // Lógica para manejar el clic en una fecha con tareas
      //console.log("Tarea seleccionada:", tarea);
      setTareaSeleccionada(tarea)
      setModalVisible(true);
    };

    function closeModal() {
      setModalVisible(false);
    }

    function calcularTiempoRestante(fechaEstimada) {
      const ahora = new Date();
      const estimada = new Date(fechaEstimada);
      const diferencia = estimada - ahora; // Diferencia en milisegundos
    
      if (diferencia < 0) {
        return 'Tiempo vencido';
      }
    
      // Calcular días, horas y minutos restantes
      const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
      const horas = Math.floor((diferencia / (1000 * 60 * 60)) % 24);
      const minutos = Math.floor((diferencia / 1000 / 60) % 60);
    
      return `${dias} días, ${horas} horas, ${minutos} minutos restantes`;
    }
    const fechaMinima = datax && datax.length > 0 ? new Date(datax[0].date) : new Date();

    const generarCalendarioConTareas = () => {
        return (
          <Calendar
          //inputRef= {(ref) =>  console.log('oli') }
          //onClickDay	= {(value, event) => console.log(event)}
          formatDay = { (locale, date) => format(date, "dd MMM", { locale: es })}
          prev2Label={null}
          next2Label={null}
          minDetail="month"
          maxDetail="month"
          minDate={fechaMinima}
          activeStartDate={fechaMinima}
          tileDisabled={({ activeStartDate, date, view }) => date <= new Date()}
          tileContent={({ date, view }) => {
            // Filtrar tareas para la fecha actual del calendario
            const tareasDelDia = datax?.filter(tarea => 
              date.toISOString().split('T')[0] === tarea.date.toISOString().split('T')[0]
            );
            // Renderizar las tareas para esta fecha
            return (
              <div className="eventCalendar">
                {tareasDelDia?.map((tarea, index) => (
                  <div className="contentEvent" key={index} onClick={() => manejarClickFecha(tarea)}>
                    <span className='d-block'>
                      {format(tarea.date, "HH", { locale: es })} hrs.
                    </span>
                    <span className=''> {tarea.title}</span>
                  </div>
                ))}
              </div>
            );
          }}
          // Agregar una clase para los días con tareas
          tileClassName={({ date, view }) => {
            const tareasDelDia = datax?.filter(tarea => 
              date.toISOString().split('T')[0] === tarea.date.toISOString().split('T')[0]
            );
            return tareasDelDia?.length > 0 ? 'diaConTarea' : null;
          }}
        />
  
        );
      };
      const tarea = datax ? datax.find((tarea) => tarea.id === tareaSeleccionada?.id) : null;

      if (modalVisible) {
        return (
          <div className="modalProyect mb-5" style={{ display: 'block' }}>
            <div className={`col-12 col-md-6`}>
              <div className={`modal-content col-12 col-md-6`}>
                  <span className="close" onClick={closeModal}>&times;</span>
                  <h5 className="modal-title">Detalle Evento</h5>
                  {tarea && (
                    <div className={`mb-1`}>
                      <p className='mb-3'><strong>{format(tarea.fechaEstimada, "eeee dd MMMM yyyy HH:mm", { locale: es })} hrs.</strong> </p>
                       <p className='mb-1'><strong>Actividad:</strong> {tarea.title}</p>
                       
                      <p className={`${tarea.Estado === 'Listas' ? 'd-none':''} mb-1`}><strong>Comienza en</strong>  {calcularTiempoRestante(tarea.fechaEstimada)}</p>
    
                      {/* Agrega aquí más detalles según necesites */}
                    </div>
                  )}
              </div>
            </div>
          </div>
        );
      }
      if (loading) return <div className="loadingLeft"><LoadingComponent darkMode={darkMode?'dark':''} text="Cargando Calendario..........." /></div>;
      if (error) return <p>Ocurrió un error: {error}</p>;

      return (
      <div className="mt-4">
          <h4 className=''>Calendario Actividades</h4>
          <p className="text-muted">Calendario con las fechas y horas de las futuras actividades ligadas al proyecto</p>
              {generarCalendarioConTareas()}

      </div> 
        );
}

export default CronogramaProyecto;