// src/components/ProjectsSection.js

import React, { useEffect, useState } from 'react';
import styles from '../../../styles/RepoSection.module.css';
import portfolioData from '../../../data/portfolioData.json';
import RepoCard from '../../Structure/RepoCard/RepoCard.js';
import { useLoading } from '../../contexts/LoadingContext';
import LoadingComponent from '../../loadingComponent/LoadingComponent.js';
import { fetchData } from '../../apiService.js';

function RepoSection({ darkMode }) {
    const endpoint = `https://api.github.com/users/${portfolioData.git}/repos?sort=updated`;
    const [loading, setLoading] = useState(false);
    const [repos, setRepos] = useState([]);
    const { setLoadingx } = useLoading();
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoadingx(true);
        setLoading(true);
        fetchData(endpoint)
        .then(data => {
            //console.log(data)
            setRepos(data);
            setLoading(false);
            setLoadingx(false);
        })
        .catch(error => {
            setError(error.message);
            setLoading(false);
            setLoadingx(false);
        });
    }, [endpoint, JSON.stringify()]);


if (loading) return <LoadingComponent darkMode={darkMode} skipAnimateText={true} text="Cargando" />;
if (error) return <p>Ocurrió un error: {error}</p>;
return (
<div className={`${styles.repoSection} + ${darkMode ? ' ' + styles.dark : ''}`}>
    <div className={styles.projectsContainer}>
        {repos.map((repo, index) => (
        <div key={index}>
            <RepoCard  data={repo} darkMode={darkMode} />
        </div>
        ))}
    </div>
</div>
);
}

export default RepoSection;
