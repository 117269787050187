import React, { useState,useEffect } from 'react';
import {Link } from 'react-router-dom';
import Header from '../header/headerComponent.js';
import styles from './ContentMobile.module.css';

/*  
Componente que se encarga de mostrar las secciones 
en la pagina inicial en la versión movil
*/

const ContentMobile = ({ routesDataIn,routeSkip,darkMode,hideContent }) => {

  const routesData = [
    { name: 'Inicio', path: '', title:'Hola! 👋', subTitle:'Te invito a visitar mi portafolio',nameButton:"" },
    { name: 'Proyectos', path: '/j/projects',title:'Proyectos 📋',subTitle:'Conoce mis proyectos realizados',nameButton:"Ver Proyectos"},
    { name: 'Servicios', path: '/j/services',title:'Servicios 🔧',subTitle:'Aquí podrás revisar el catalogo de servicios que ofrezco',nameButton:"Ver Servicios"},
    { name: 'Repositorios', path: '/j/reposit',title:'Repositorios 👨‍💻',subTitle:'Repositorio de código para reutilizar en tus proyectos',nameButton:"Ver Repositorios" },
    { name: 'Blog', path: '/j/blog',title:'Blogs 📰',subTitle:'Encuentra noticias y novedades de informática',nameButton:"Ver Noticias" },
    { name: 'Contacto', path: '/j/contact',title:'Contacto 📩',subTitle:'Tienes alguna duda? Escríbeme',nameButton:"Contactar" },
    { name: 'Acceso', path: '/j/login',title:'Login',subTitle:'',nameButton:"Login"},
    { name: 'Administración', path: '/j/admin',title:'Admin Zone',subTitle:'',"description":"",nameButton:"Admin Zone"},
  ];
  routesData.push({name: '', path: '',title:'',subTitle:''});
 
  const firstIndex = routesData.findIndex(item => item.name === routeSkip);
  const skippedItem = routesData.splice(firstIndex, 2)[1];
  routesData.unshift(skippedItem);

  const scrollTop = () => {
    document.querySelector('section').scrollTo(0, 0);
  }
    return (
        <div className={`${styles.contentMobile}`}>
            {routesData
            .filter(routeData => routeData && routeData.name !== 'Inicio' && routeData.name !== '' && routeData.name !== 'Acceso' && routeData.name !== 'Administración')
            .map((routeData,index) => (
                <div key={index} className={`${!hideContent ? '':'d-none'}`}>
                    <Header
                        title={routeData.title}
                        subtitle={routeData.subTitle}
                        description={routeData.description}
                    />
                    <Link 
                      to={routeData.path} 
                      onClick={() => {scrollTop()}}
                      className={`${darkMode ? styles.dark : ''} ${styles.btnSection}`}
                    >
                      {routeData.nameButton}
                    </Link>
                    <div className='adjustMovil'></div>
                </div>
            ))}
        </div>
    );
  }
  
  
  export default ContentMobile;