// ApiService.js

const API_BASE_URL = 'https://tupanoramaurbano.cl/api/wp/v2/javi';

// Función genérica para obtener datos de la API de WordPress
export const fetchData = async (endpoint = {}) => {
  // Construye la URL con parámetros de búsqueda si los hay
  var url = new URL(`${API_BASE_URL}${endpoint}`);
  if(endpoint.indexOf('http') > -1){
     url = new URL(`${endpoint}`);
  }
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Error al obtener los datos');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error en fetchData: ", error);
    throw error;
  }
};
