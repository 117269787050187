import React, { useState, useEffect } from 'react';

  
  const NotFound = ({ darkMode }) => {
  
    return (
        <>404 Sitio no encontrado...</>
    );
  };
  
  export default NotFound;