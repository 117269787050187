import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.js';
import styles from './loginStyle.module.css'
import { useNavigate } from 'react-router-dom';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';

const Login = ({ darkMode }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, authData, validateToken, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(false);
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    // Función para verificar la validez del token si existe
    const checkTokenValidity = async () => {
      if (authData && authData.token) {
        const isValid = await validateToken();
        setIsValidToken(isValid);
      }
      setIsLoading(false);
    };

    // Si no hay datos de autenticación en el localStorage, no hay necesidad de cargar
    if (!localStorage.getItem('authData')) {
      setIsLoading(false);
      setIsValidToken(false);
    } 
    else {
      setIsLoading(false);
      //checkTokenValidity();
    }
  }, [authData, validateToken]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setLoginError(''); // Limpiar errores anteriores

    try {
      await login(username, password);
      navigate("/j/admin");
      // Si el login es exitoso, el código continúa (posiblemente redirigiendo al usuario)
    } catch (error) {
      //console.log(error)
      // Manejar errores de inicio de sesión aquí
      setLoginError('Error al iniciar sesión, intente nuevamente'); // Almacena el mensaje de error
    } finally {
      setIsLoading(false); // Finalizar carga independientemente del resultado
    }
  };
  

  const handleLogout = () => {
    setIsLoading(true);
    logout();
    setIsValidToken(false);
    setIsLoading(false);
  };

  // Si está cargando, muestra el mensaje de carga
  if (isLoading) {
    return( 
      <LoadingComponent darkMode={darkMode} text="Validando su acceso, por favor espere" />
    );
  }

  // Si el token es válido y no está cargando, mostrar un saludo
  if (authData && authData.userName) {
    return (
      <>
        <p>👋 Hola <strong>{authData.name}</strong></p>
        <p>Le doy la bienvenida. Su sesión se encuentra abierta.</p>
        <button onClick={handleLogout}>Cerrar sesión</button>
      </>
    );
  }

  // Si no hay token válido, muestra el formulario de inicio de sesión
  return (

    <form onSubmit={handleSubmit} className={`${darkMode ? styles.dark : ''}`}>
  <div className="container h-100">
    <div className="row d-flex justify-content-center align-items-center h-100">
      <div className="col-12 col-md-9 col-lg-8 col-xl-7" >
        <div className={`card ${styles.card} ${darkMode ? styles.dark : ''}`}>
          <div className="card-body px-xl-5 px-3 text-center">

            <div className="mb-md-3 mt-md-4 pb-1">

              <h2 className="fw-bold mb-2 text-uppercase">Hola 👋!</h2>
              <p className="mb-3">Para acceder por favor ingrese usuario y contraseña</p>

              <div className="form-outline mb-3">
                <input  type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Nombre de usuario" id="typeEmailX" className="form-control form-control-lg" />
                {/*<label className={`form-label ${darkMode ? styles.dark : ''}`} htmlFor="typeEmailX">Usuario</label>*/}
              </div>

              <div className="form-outline mb-4">
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Contraseña" id="typePasswordX" className="form-control form-control-lg" />
                 {/*<label className={`form-label ${darkMode ? styles.dark : ''}`} htmlFor="typePasswordX">Contraseña</label>*/}
              </div>
              
              {loginError && <p className="alert alert-danger">{loginError}</p>}
              <button className="btn btn-primary btn-lg px-5" type="submit">Ingresar</button>

              <div className="d-flex justify-content-center text-center mt-4 pt-1">
                <a href="#!" className=""><i className="fab fa-facebook-f fa-lg"></i></a>
                <a href="#!" className=""><i className="fab fa-twitter fa-lg mx-4 px-2"></i></a>
                <a href="#!" className=""><i className="fab fa-google fa-lg"></i></a>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

    </form>


  );
};

export default Login;
