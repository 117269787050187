// src/components/BlogSection.js

import React, { useState, useEffect } from 'react';
import styles from '../../../styles/BlogSection.module.css';
import BlogCard from '../../Structure/BlogCard/BlogCard';
import { fetchData } from '../../apiService.js';
import { useLoading } from '../../contexts/LoadingContext';
import LoadingComponent from '../../loadingComponent/LoadingComponent.js';

const DataComponent = ({darkmod}) => {
  const endpoint = '/blog';
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setLoadingx } = useLoading();

  useEffect(() => {
    setLoadingx(true);
    setLoading(true);
    fetchData(endpoint)
      .then(data => {
        setData(data);
        setLoading(false);
        setLoadingx(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
        setLoadingx(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify()]);

  if (loading) return <LoadingComponent darkMode={darkmod} skipAnimateText={true} text="Buscando Noticias sobre informática..." />;
  if (error) return <p>Ocurrió un error: {error}</p>;

  return (
    <div className={`${styles.blogSection} ${darkmod ? styles.dark : ''}`}>
      {/* Renderizar datos basados en el tipo de datos que se espera */}
      {data && (
        <div className={styles.projectsContainer}>
          {/* Ejemplo si se espera una lista de posts */}
            {
            data.map((blog, index) => ( 
                  <BlogCard
                  key={index}
                  title={blog.title}
                  excerpt={blog.description}
                  image={blog.img}
                  date={blog.fecha_publica}
                  author={blog.author}
                  category={blog.cat}
                  url={blog.id_slug ? 'article/'+blog.id_slug : blog.url}
                  darkMode={darkmod}
                />
            ))}
        </div>
      )}
    </div>
  );
};

const BlogSection = ({ darkMode }) => {

  return (
      <DataComponent darkmod = {darkMode}/>
  );
};

export default BlogSection;

