import React, { useState, useEffect } from 'react';
import { useParams,useNavigate,useLocation    } from 'react-router-dom';
import { fetchData } from '../apiService.js';
import TypingEffect from '../TypingEffect/TypingEffect.js';
import Article from './ArticleCard.js';

function ArticleComponent() {
    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [datax, setDatax] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const endpoint = '/articles/'+slug;
    const navigate = useNavigate();
    const location = useLocation();
    //console.log(location.pathname)  
    useEffect(() => {
      setLoading(true);
      fetchData(endpoint)
        .then(data => {
          setData(data[0]);
          setLoading(false);
        })
        .catch(error => {
          setError(error.message);
          setLoading(false);
          navigate('/j/notFound');
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, JSON.stringify()]);
  

//console.log(data)
if (loading) return <TypingEffect text='Cargando Articulo..........' />;
if (error) return <p>Ocurrió un error: {error}</p>;
return (
    <div>

      {data ? <Article article={data} urlShare = {location.pathname}/> : <p>Cargando artículo...</p>}
    </div>
  );
}

export default ArticleComponent;
