import React, { useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import ProyectCard from './ProyectCard.js';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import { useAuth } from '../login/AuthContext.js';

function ProyectComponent({darkMode}) {
  
    const {authData,logout} = useAuth();
    const { slug } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const endpoint = 'getProyect/'+slug;
    const navigate = useNavigate();

    const token = authData ? authData.token:null;
    const user_id = authData ? authData.user_id.toString():null;

    //console.log(location.pathname)  
    useEffect(() => {
      setLoading(true);

      const dataToSend = { user_id };
      fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(dataToSend)
        }).then(async (response) => {
          const data = await response.json();
          if(response.status === 403){
            logout();
            navigate('/j/login');
            return;
          }
          if (!response.ok) {
            throw new Error(data.message || 'Error en la solicitud');
          }
          if(data.length == 0){
            navigate('/j/notFound');
            return;
          }
          setData(data[0]);
          setLoading(false);
        }).catch(error => {
          console.error("Error en fetchData: ", error);
          setError(error.message);
          setLoading(false);
          //navigate('/j/notFound');
          throw error;
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endpoint, JSON.stringify()]);
  

//console.log(data)
if (loading) return <LoadingComponent darkMode={darkMode} text="Cargando Proyecto...." />;
if (error) return <p>Ocurrió un error: {error}</p>;
return (
    <div>

      {data ? <ProyectCard data={data} /> : <LoadingComponent darkMode={darkMode} skipAnimateText={true} text="Cargando Proyecto...." />}
    </div>
  );
}

export default ProyectComponent;
