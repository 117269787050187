import React, { useState, useEffect } from 'react';
import { useAuth } from '../login/AuthContext.js';
import { Link } from 'react-router-dom';
import TypingEffect from '../TypingEffect/TypingEffect.js';

const ClienteZone = () => {
  
  const {authData,logout} = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [clientData, setClientData] = useState(null);
  
  useEffect(() => {
    setIsLoading(true);
    // Si no hay datos de autenticación en el localStorage, no hay necesidad de cargar
    if (!localStorage.getItem('authData')) {
      setIsLoading(false);
    } 
    else {
      setIsLoading(false);
      //console.log(Object.values(JSON.parse(localStorage.getItem('authData'))))
      setClientData(JSON.parse(localStorage.getItem('authData')))
      //console.log(JSON.parse(localStorage.getItem('authData')))
      //checkTokenValidity();
    }
  }, [authData]);

  const handleLogout = () => {
    setIsLoading(true);
    logout();
    setIsLoading(false);
  };

  // Si está cargando, muestra el mensaje de carga
  if (isLoading) {
    return <TypingEffect text='Cargando su Información..........' />;
  }

  // Si el token es válido y no está cargando, mostrar un saludo
  if (authData && authData.userName) {
    return (
      <>
      <p>Aquí podrá encontrar toda la información relacionada con tus Proyectos</p>
      <div className="">
        <h4>Datos Cliente</h4>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Fono</th>
                <th>Correo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Nombre">{clientData.name}</td>
                <td data-label="Fono">{clientData.user_phone}</td>
                <td data-label="Correo">{clientData.user_mail}</td>
              </tr>
            </tbody>
          </table>
        </div>  
      </div> 

      <div className="mt-4">
        <h4>Proyectos</h4>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Estado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {clientData.proyects.map((proyect) => (
              <tr key={proyect.id}>
                <td data-label="Nombre">
                  <Link to={'/j/proyecto/'+proyect.id}>
                    {proyect.Nombre}
                  </Link>
                </td>
                <td data-label="Estado">{proyect.estadoText}</td>
                <td data-label="">
                <Link to={'/j/proyecto/'+proyect.id}>
                  <button type="button" className="btn btn-primary">Ver</button>
                </Link>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>  
      </div> 
    </>
    );
  }

  // Si no hay token válido, muestra el formulario de inicio de sesión
  return (
    <>Para acceder aquí primero debe registrarse</>
  );
};

export default ClienteZone;