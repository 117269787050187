import React, { useState, useEffect } from 'react';
import { useAuth } from '../login/AuthContext.js';
import { useNavigate } from 'react-router-dom';
import ApiAdmin from './ApiAdmin.js'
import ClienteZone from '../ClienteComponent/ClienteZone.js';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import ClientesList from './ClientesList.js';

const AdminZone = (darkMode) => {
  const {authData,logout} = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [sectionGet, setSection] = useState(null);
  const [sectionUse, setSectionUse] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setSectionUse('home');
    checkTokenValidity()
    // Si no hay datos de autenticación en el localStorage, no hay necesidad de cargar
    if (!localStorage.getItem('authData')) {
      setIsLoading(false);
    } 
    else {
      setIsLoading(false);
      //checkTokenValidity();
    }
  }, [authData]);

  const handleLogout = () => {
    setIsLoading(true);
    logout();
    navigate('/j/login');
    setIsLoading(false);
  };

  const selectMenu = (section) => {
    setIsLoading(true);
    setSection(section);
    setIsLoading(false);
  };

  const selectSection = (section) => {
    setIsLoading(true);
    setSectionUse(section);
    setIsLoading(false);
  };

  const token     = authData ? authData.token:null;
  const user_id   = authData ? authData.user_id.toString():null;
  const dateInit  = authData ? authData.dateInit:null;

  var limitToken =   new Date(dateInit).setDate(new Date(dateInit).getDate() + 1).valueOf();
  var hoy = new Date().valueOf();

  function checkTokenValidity() {
    if(hoy > limitToken){
      handleLogout();
      navigate('/j/login');
    }
  }
  
  const handleSuccess = (data) => {
    console.log('Operación exitosa:', data);
    // Aquí puedes manejar la respuesta exitosa, como mostrar un mensaje de éxito
  };

  const handleFailure = (error) => {
    console.error('Operación fallida:', error);
    // Aquí puedes manejar el error, como mostrar un mensaje de error
  }; 

  
  const backMenu = () =>{
    setSectionUse('home');
  }


  // Si está cargando, muestra el mensaje de carga
  if (isLoading) {
    return <LoadingComponent darkMode={darkMode} text="Cargando Zona de Administración" />;
  }

  if(authData && authData.userName && JSON.parse(localStorage.getItem('authData')).rol == 'cliente_javi'){
    return(
      <>
      <ClienteZone/>
      </>
    )
  }

  
  // Si el token es válido y no está cargando, mostrar un saludo
  if (authData && authData.userName && sectionUse === 'home') {
    return (
      <>
        <p>Hola! ahora estas en la sección de Administración</p>
        <div className='mb-3'>
          <button type="button" className="btn btn-info mb-3 me-1" onClick={() => selectSection('admin')}>Admin Web</button>
          <button type="button" className="btn btn-info mb-3 me-1" onClick={() => selectSection('clientes')}>Admin Client</button>
        </div>
        </>
        )
    }


  if(authData && authData.userName && sectionUse === 'clientes'){
    return(
      <>
        <button type="button" className="btn btn-warning mb-3 me-1" onClick={() => backMenu()}>Volver</button>
        <ClientesList slug={''} darkMode={darkMode} />
      </>
    );
  }


  // Si el token es válido y no está cargando, mostrar un saludo
  if (authData && authData.userName && sectionUse === 'admin') {
    return (
      <>
        <p>Hola! ahora estas en la sección de Administración</p>
        <button type="button" className="btn btn-warning mb-3 me-1" onClick={() => backMenu()}>Volver</button>
        <div className='mb-3'>
          <button type="button" className="btn btn-info mb-3 me-1" onClick={() => selectMenu('proyect')}>Proyectos</button>
          <button type="button" className="btn btn-info mb-3 me-1" onClick={() => selectMenu('service')}>Servicios</button>
          <button type="button" className="btn btn-info mb-3" onClick={() => selectMenu('blog')}>Blogs</button>
        </div>
        <ApiAdmin
          endpoint="crear"
          method="POST"
          token={token}
          type={sectionGet} // Cambia esto según sea necesario (proyect, service, blog)
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          user_id ={user_id}
        />
      </>
    );
  }

  // Si no hay token válido, muestra el formulario de inicio de sesión
  return (
    <>Para acceder aqui primero debe registrarse</>
  );
};

export default AdminZone;
