import React from 'react';
import TypingEffect from '../TypingEffect/TypingEffect.js';
import loadingCat from '../../assets/loadingCat.gif';
import styles from './LoadingComponent.module.css';

const LoadingComponent = ({ darkMode, text, skipAnimateText }) => {

    return (
        <div className="text-center mb-3">
            <h5 className={`mb-4 mt-1 ${darkMode ? styles.dark : ''}`}>
                <TypingEffect text={text} speed={skipAnimateText ?? 0}/>
            </h5>
            <div className="contieneLoading">
                <img id="imgLoading" src={loadingCat} alt="loading" />
                <p className={`textoLoading ${darkMode ? styles.dark : ''}`} style={{'textShadow':'black 2px 1px 4px'}}>
                <TypingEffect 
                showCursor={'no'} 
                speed={1000}
                fontSize={'1.5rem'} 
                text={'☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕☕'} />
                </p>
            </div>
        </div>
    );
};

export default LoadingComponent;
