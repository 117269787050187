import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    // Intenta obtener los datos de la sesión desde el almacenamiento local
    const storedData = localStorage.getItem('authData');
    //console.log(storedData)
    return storedData ? JSON.parse(storedData) : null;
  });

  // Función para actualizar los datos de la sesión
  const updateAuthData = (newData) => {
    setAuthData(newData);
    if (newData === null) {
      localStorage.removeItem('authData');
    } else {
      localStorage.setItem('authData', JSON.stringify(newData));
    }
  };

  // Función para validar el token (podrías implementar la lógica de verificación aquí)
  const validateToken = async () => {
    try {
      // Asumimos que 'authData.token' contiene el token que quieres validar
      const response = await fetch('https://tupanoramaurbano.cl/api/wp/v2/javi/validate_token/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Agrega aquí cualquier otra cabecera que sea necesaria
        },
        body: JSON.stringify({ user_id:authData.user_id, token:authData.token }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Asegúrate de que estás accediendo a la propiedad correcta de la respuesta
      return data.valid;
  
    } catch (error) {
      console.error('Error al validar token:', error);
      return false;
    }
  };


// Función de login
const login = async (username, password) => {
  try {
    const response = await fetch('https://tupanoramaurbano.cl/api/wp/v2/javi/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error en la autenticación');
    }

    const data = await response.json();
    //console.log(data)
    updateAuthData({
      token: data.token,
      user_id: data.user_id,
      userName: data.userName,
      name: data.name,
      rol: data.rol,
      dateInit: new Date(),
      user_mail : data.user_email,
      user_phone : data.user_phone,
      proyects : data.proyects,
    });

  } catch (e) {
    //console.error('Error al iniciar sesión:', error);
    throw new Error(e); // Lanza el error para que pueda ser capturado en el componente
  }
};


  // Función de logout
  const logout = () => {
    updateAuthData(null);
  };

  return (
    <AuthContext.Provider value={{ authData, updateAuthData, validateToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
