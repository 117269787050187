// src/components/ProjectsSection.js

import React, { useState } from 'react';
import styles from '../../../styles/ContactSection.module.css';
import { useAuth } from '../../login/AuthContext.js'; // Asegúrate de usar la ruta correcta

function Contact({ darkMode }) {
  const { authData } = useAuth();
  const [status, setStatus] = useState({ ok: null, msg: "" });
  const [loading, setLoading] = useState('');

  const handleChange = ()=>{
    setStatus({ ok: null, msg: "" });
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const formData = {
      nombre: event.target.name.value,
      email: event.target.email.value,
      asunto: event.target.subject.value,
      mensaje: event.target.message.value
    };

    try {
      const response = await fetch('https://tupanoramaurbano.cl/api/wp/v2/javi/contacto', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Error en el envío del formulario');
      }

      const result = await response.json();
      //console.log(result.code)
      if(result.code == 'envio_limitado'){
        setStatus({ 
          ok: true, 
          msg: "👏💬 Ya habiamos recibido un mensaje de su parte, pronto nos pondremos en contacto." });
      }else{
        setStatus({ 
          ok: true, 
          msg: "👏💌 Su mensaje ha sido enviado correctamente, en breve estaremos en contacto." });
      }
      
      //localStorage.setItem('authData', JSON.stringify(newData));
      setLoading(false);

    } catch (error) {
      console.log(error)
      setStatus({ ok: false, msg: "Ha ocurrido un error al enviar mensaje, favor intente nuevamente." });
      setLoading(false);

    }
    
  };
  if (loading) {
    document.querySelector('section').scrollTo({ top: 0, behavior: 'smooth' });
    return (    
    <div className={darkMode ? styles.dark : ''}>  
    <div className={styles.contactContainer}>
      <form autoComplete="on" onSubmit={handleSubmit} onChange={handleChange} className={styles.contactForm}>
        <button disabled={loading} className={loading ? styles.loading : styles.button} type="submit">  <p className={styles.textLoad}>{loading ? 'Enviar 🚀' : 'Enviar 🚀'}</p></button>
        <p className={styles.textLoad}>{loading ? 'Enviando...' : ''}</p>
      </form>
    </div>
  </div>);
  }

  if (status.ok !== null && status.ok) {
    return (
      <div className={darkMode ? styles.dark : ''}>  
    <div className={styles.contactContainer}>
      <form autoComplete="on" onSubmit={handleSubmit} onChange={handleChange} className={styles.contactForm}>
      <div className={`alert alert-success ${styles.statusMessage} ${status.ok ? styles.success : styles.error}`}>
        {status.msg}
      </div>
      </form>
    </div>
  </div>);
  }

  return (
    <div className={darkMode ? styles.dark : ''}>  
    
      <div className={styles.contactContainer}>
        <form autoComplete="on" onSubmit={handleSubmit} onChange={handleChange} className={styles.contactForm}>
        {status.msg && <div className={`alert alert-danger ${styles.statusMessage}`}>{status.msg}</div>}
          <div className='row'>
          <div className={`col-lg-6 ${styles.formGroup}`}>
            <label htmlFor="name">Su Nombre</label>
            <input type="text" id="name" name="name" defaultValue={authData && authData.name !== null ? authData.name:'' } required />
          </div>

          <div className={`col-lg-6 ${styles.formGroup}`}>
            <label htmlFor="email">Correo electrónico</label>
            <input type="email" id="email" name="email" required />
          </div>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="subject">Asunto</label>
            <input type="text" id="subject" name="subject" required />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="message">Mensaje</label>
            <textarea id="message" name="message" rows="4" required></textarea>
          </div>

          <button disabled={loading} className={loading ? styles.loading : styles.button} type="submit">  <p className={styles.textLoad}>{loading ? '' : 'Enviar 🚀'}</p></button>
          <p className={styles.textLoad}>{loading ? 'Enviando...' : ''}</p>
        </form>
      </div>
    </div>
  );
}
export default Contact;
