import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../login/AuthContext.js';

function HistorialProyectos(slug,{darkMode }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState(null);
  const [openAdd, setopenAdd] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [shouldReload , setShouldReload] = useState('init');
  const endpoint = 'getHistoryProyect/' + slug.slug;



  const {authData,logout} = useAuth();
  const navigate = useNavigate();

  const token = authData ? authData.token:null;
  const user_id = authData ? authData.user_id.toString():null;
  const rolUser = authData ? authData.rol:null;
  
  //console.log(location.pathname)  
  useEffect(() => {
    if (shouldReload === 'init' || shouldReload === 'reload'|| shouldReload === 'reloadEdit' || shouldReload === 'reloadDelete') {
      if(rolUser === 'admin_javi'){
        setisAdmin(true);
      }
    setShouldReload('noReload');
    setopenAdd(false)
    setModalVisible(false);
    setModalEditar(false);
    setModalEliminar(false);
    setLoading(true);
    const dataToSend = { user_id };
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        const data = await response.json();
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
        setData(data);
        setLoading(false);
      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoading(false);
        //navigate('/j/notFound');
        throw error;
      });
    }
  }, [endpoint, JSON.stringify(),shouldReload]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    const dataForm = new FormData(e.target);
    const type = 'history';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      tipoHistoria: parseInt(dataForm.get("tipoHistoria")),
      fecha: dataForm.get("fecha"),
      detalle: dataForm.get("detalle"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.tipoHistoria === '' || dataToSend.fecha === '' || dataToSend.detalle === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/crearProyect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reload');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    const dataForm = new FormData(e.target);
    const type = 'history';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      tipoHistoria: parseInt(dataForm.get("tipoHistoria")),
      fecha: dataForm.get("fecha"),
      detalle: dataForm.get("detalle"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.tipoHistoria === '' || dataToSend.fecha === '' || dataToSend.detalle === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/editarProyect/${registroSeleccionado}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadEdit');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleDelete = async () => {
    //setLoading(true);
    const type = 'history';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      user_id,
      token,
      type,
      idProyecto
    };
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/eliminarProyect/${registroSeleccionado}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadDelete');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  //console.log(rolUser)

  const renderButtonAddAdmin = () => {
    if(isAdmin){
      return (
        <div className="container">
          <div className="row">
            <button className="btn btn-primary" onClick={() => setopenAdd(!openAdd)}><i className="bi bi-plus-circle"></i> Agregar Registro</button>
          </div>
          <form className="row g-3 mt-3" style={{display: openAdd ? '':'none'}} onSubmit={handleSubmit}>
          <div className="col-md-6">
              <label htmlFor="tipoHistoria" className="form-label">Tipo</label>
              <select className="form-control" id="tipoHistoria" name="tipoHistoria" placeholder="Fecha de la actividad" >
                <option value="1">Reunión</option>
                <option value="2">Cambio</option>
                <option value="3">Otro</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="fecha" className="form-label">Fecha</label>
              <input type="datetime-local" className="form-control" id="fecha" name="fecha" placeholder="Fecha de la actividad" />
            </div>
            <div className="col-md-12">
              <label htmlFor="detalle" className="form-label">Detalle</label>
              <textarea className="form-control" id="detalle" name="detalle" placeholder="Detalle de la actividad" >
              </textarea>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary" disabled={loadingx}>
               {loadingx ? 'Enviando...' : 'Registrar'}
              </button>            
              </div>
          </form>
        </div>
      )
    }
  }




  function openModal(id) {
    

    setRegistroSeleccionado(id);
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  function openModalEdit(id) {
    console.log('Editar: ', id);
    setModalEditar(true);
    setModalEliminar(false);
    setRegistroSeleccionado(id);
    setModalVisible(false);
  }

  function closeModalEdit(){
    setModalEditar(false);
  }

  function openModalDelete(id) {
    console.log('Editar: ', id);
    setModalEditar(false);
    setModalEliminar(true);
    setRegistroSeleccionado(id);
    setModalVisible(false);
  }

  if (loading) return <div className="loadingLeft"><LoadingComponent darkMode={darkMode?'dark':''} skipAnimateText={true} text="Cargando Historial..........." /></div>;
  
  if (error) return <p>Ocurrió un error: {error}</p>;
  
  const tarea = data ? data.find(tarea => tarea.id === registroSeleccionado):null;
  if (modalVisible){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={closeModal}>&times;</span>
          <h5>Detalle Registro</h5>
          {tarea && (
            <div className='mt-3'>
              <p className='mb-1'><strong>Actividad:</strong> {tarea.nombre}</p>
              <p className='mb-1'><strong>Detalle:</strong> {tarea.detalle}</p>
              <p className='mb-1'><strong>Fecha:</strong> {format(tarea.fecha, "EEEE dd MMMM yyyy HH:mm", { locale: es })} hrs.</p>
              {/* Agrega aquí más detalles según necesites */}
            </div>
          )}
        </div>
        {tarea && isAdmin && (
          <>
          <div className="modal-footer">
            <button className="btn btn-primary btn-sm" onClick={() => openModalEdit(tarea.id)}>Editar</button>
            <button className="btn btn-danger btn-sm" onClick={() => openModalDelete(tarea.id)}>Eliminar</button>
          </div>

          </>
        )}
        </div>
      </div>
    );
  }

  if (modalEditar){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={closeModalEdit}>&times;</span>
          <h5>Editar Registro</h5>
          {tarea && (
            <form className="row g-3 mt-3" onSubmit={handleSaveEdit}>
            <div className='mt-3 row'>
            <div className="form-group col-md-6">
              <label htmlFor="tipoHistoria" className="form-label">Tipo</label>
              <select defaultValue={tarea.idHistory} className="form-control" id="tipoHistoria" name="tipoHistoria" placeholder="Fecha de la actividad" >
                <option value="1">Reunión</option>
                <option value="2">Cambio</option>
                <option value="3">Otro</option>
              </select>
            </div>
            
            <div className="form-group col-md-6">
                <label htmlFor="fecha">Fecha</label>
                <input
                  type="datetime-local"
                  className="form-control"
                  id="fecha"
                  name="fecha"
                  defaultValue={tarea.fecha}
                />
              </div>
              <div className="form-group col-md-12">
                <label htmlFor="detalle">Detalle</label>
                <textarea
                  className="form-control"
                  id="detalle"
                  name="detalle"
                  defaultValue={tarea.detalle}
                ></textarea>
              </div>
              {/* Add more editable inputs here if needed */}
            </div>
            <button type="submit" className="btn btn-primary" disabled={loadingx}>
               {loadingx ? 'Guardando...' : 'Guardar'}
              </button>   
            </form>
          )}
        </div>
        {tarea && isAdmin && (
          <>
            <div className="modal-footer">
             
              <button className="btn btn-danger btn-sm" onClick={() => openModalDelete(tarea.id)}>Eliminar</button>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }


  if (modalEliminar){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={() => setModalEliminar(false)}>&times;</span>
          <h5>Eliminar Registro</h5>
          {tarea && (
            <div className='mt-3'>
              <p className='mb-1'><strong>Actividad:</strong> {tarea.nombre}</p>
              <p className='mb-1'><strong>Detalle:</strong> {tarea.detalle}</p>
              <p className='mb-1'><strong>Fecha:</strong> {format(tarea.fecha, "EEEE dd MMMM yyyy HH:mm", { locale: es })} hrs.</p>
              {/* Agrega aquí más detalles según necesites */}
            </div>
          )}
        </div>
        {tarea && isAdmin && (
          <>
            <div className="modal-footer">
              <button className="btn btn-danger btn-sm" onClick={() => handleDelete()} disabled={loadingx}>
                {loadingx ? 'Eliminando...' : 'Confirmar Eliminar'}
              </button>
             
              <button className="btn btn-info btn-sm" onClick={() => setModalEliminar(false)} disabled={loadingx}>
                Cancelar
                </button>
            </div>
          </>
        )}
      </div>
    </div>
    )
  }





  return (
    <div className="container mt-4">
      <h4 className=''>Historial del Proyecto</h4>
      
      <p className="text-muted">Registro de todos los acontecimientos ocurridos durante la historia del proyecto.</p>
      
      <div className="col-12 col-md-6 mb-3">
        {renderButtonAddAdmin()}
      </div>

      {data && data.length === 0 ? (
        <div className="alert alert-info" role="alert">
          No hay registros de actividades.
        </div>
      ) : (
        <div className="accordion col-12 col-md-6" id="historialAccordion">
          {data && data.map((registro, index) => (
            
            <div className="accordion-item mb-3" key={index}>
              
              <h2 className={`accordion-header`} id={`heading-${registro.id}`}>
                <button className={`accordion-button ${registro.id == registroSeleccionado ? 'bg-warning':''}`} onClick={() => openModal(registro.id)} type="button">
                  {registro.nombre} - {format(new Date(registro.fecha), "EE dd/MM/yyyy HH:MM", { locale: es })}
                </button>

              </h2>
              
              <div className="">
              <button className="btn btn-primary col-12" onClick={() => openModal(registro.id)}>Ver Registro</button>
              </div>
              <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#historialAccordion">
                                  <button className="btn btn-primary btn-sm" onClick={() => openModal(registro.id)}>Ver Más</button>

                <div className="accordion-body">
                  <p><strong>Detalle:</strong> {registro.detalle}</p>
                  <p><strong>Fecha:</strong> {format(new Date(registro.fecha), "EEEE dd MMMM yyyy HH:mm", { locale: es })} hrs.</p>
                  {/* Agrega aquí más detalles según necesites */}
                  <button className="btn btn-primary btn-sm" onClick={() => openModal(registro.id)}>Ver Más</button>
                </div>
              </div>
            </div>
          ))}
        </div>
        
      )}

    </div>
  );
}

export default HistorialProyectos;
