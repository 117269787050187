// src/components/ProjectsSection.js

import React, { useState, useEffect } from 'react';
import styles from '../../../styles/ServicesSection.module.css';
import ServiceCard from '../../Structure/ServiceCard/ServiceCard.js';
import { fetchData } from '../../apiService.js';
import { useLoading } from '../../contexts/LoadingContext';
import LoadingComponent from '../../loadingComponent/LoadingComponent.js';

const endpoint = '/servicios';
const DataComponent = ({darkmod}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { setLoadingx } = useLoading();

  useEffect(() => {
    setLoadingx(true);
    setLoading(true);
    fetchData(endpoint)
      .then(data => {
        setData(data);
        setLoading(false);
        setLoadingx(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
        setLoadingx(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify()]);

  if (loading) return <LoadingComponent darkMode={darkmod} skipAnimateText={true} text="Cargando" />;
  if (error) return <p>Ocurrió un error: {error}</p>;
 
  return (
    <div className={`${darkmod ? styles.dark : ''}`}>
      {/* Renderizar datos basados en el tipo de datos que se espera */}
      {data && (
        <div className={styles.projectsContainer}>
          {/* Ejemplo si se espera una lista de posts */}
            {data.map((service, index) => (
                  <ServiceCard
                  key={index}
                  title={service.title}
                  description={service.descript}
                  icon={service.emoji}
                  darkMode={darkmod}
                  
                />
            ))}
        </div>
      )}
    </div>
  );
};

function ServicesSection({ darkMode }) {

  return (
      <DataComponent darkmod = {darkMode}/>
  );
}

export default ServicesSection;

