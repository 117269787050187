import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importar estilos
import styles from './AdminStyle.module.css';
import imageToBase64 from 'image-to-base64/browser';


const ApiAdmin = ({ endpoint, method, token, type, onSuccess, onFailure, user_id }) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [caracteristicas, setfeatures] = useState([]);
  const [tecnolo, settecno] = useState([]);
  const [detalles, setDetalles] = useState('');
  const [img, setImagen] = useState(null);

  const handleChange = (e) => {
    //console.log(e.target.id)
    if(e.target.id  == 'imageBlog'){
      imageToBase64 ( e.target.value )
      .then(
        (response) => {
          console.log('data:image/png;base64,'+response); // "cGF0aC90by9maWxlLmpwZw=="
          setImagen('data:image/png;base64,'+response)
        }
      )
      .catch(
        (error) => {
          console.log(error); // Logs an error if there was one
        }
      )
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputChange = (e) => {
    setfeatures( e.target.value.split(','))
  };

  const handleInputChangeTec = (e) => {
    settecno( e.target.value.split(','))
  };

  const handleTAChange = (e) => {
    //console.log(e)
    setDetalles(e);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const dataToSend = { ...formData, user_id, type, tecnolo,caracteristicas,detalles,img};
    try {
      const response = await fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Error en la solicitud');
      }

      onSuccess(data);
    } catch (error) {
      onFailure(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  const renderInputs = () => {
    switch (type) {
      case 'proyect':
        return (
          <>
            <h3 className="mb-4">Subir Proyecto</h3>
            <div className="form-floating mb-3" >
              <input className="form-control" name="title" id="title" onChange={handleChange} value={formData.title || ''} />
              <label htmlFor="title" className="form-label">Título</label>
            </div>
            <div className="form-floating mb-3" >
              <input className="form-control" name="subtitle" id="subtitle" onChange={handleChange} value={formData.subtitle || ''} />
              <label htmlFor="subtitle" className="form-label">Subtítulo</label>
            </div>
            <div className="form-floating mb-3" >
              <textarea className="form-control" name="descript" id="descript" rows="3" onChange={handleChange} value={formData.descript || ''}></textarea>
              <label htmlFor="descript" className="form-label">Descripción</label>
            </div>
            <div className="form-floating mb-3" >
              <input type="text" className="form-control" id="features" onChange={handleInputChange} />
              <label htmlFor="features" className="form-label">Características</label>
            </div>
            <div className="form-floating mb-3" >
              <input type="text" className="form-control" id="technologies" onChange={handleInputChangeTec} />
              <label htmlFor="technologies" className="form-label">Tecnologías</label>
            </div>
            <div className="form-floating mb-3" >
              <input className="form-control" name="url" id="url" onChange={handleChange} value={formData.url || ''} />
              <label htmlFor="url" className="form-label">URL</label>
            </div>
          </>
        );
      
        case 'service':
          return (
            <>
              <h3 className="mb-4">Subir Servicio</h3>
              <div className="form-floating mb-3" >
                <input className="form-control" name="title" id="titleService" onChange={handleChange} value={formData.title || ''} />
                <label htmlFor="titleService" className="form-label">Título</label>
              </div>
              <div className="form-floating mb-3" >
                <input className="form-control" name="emoji" id="emojiService" onChange={handleChange} value={formData.emoji || ''} />
                <label htmlFor="emojiService" className="form-label">Emoji</label>
              </div>
              <div className="form-floating mb-3" >
                <textarea className="form-control" name="descript" id="descriptionService" rows="3" onChange={handleChange} value={formData.descript || ''}></textarea>
                <label htmlFor="descriptionService" className="form-label">Descripción</label>
              </div>
            </>
          );
        
          case 'blog':
            return (
              <>
                <h3 className="mb-4">Subir Blog</h3>
                <div className="form-floating mb-3" >
                  <input className="form-control" name="title" id="titleBlog" onChange={handleChange} value={formData.title || ''} />
                  <label htmlFor="titleBlog" className="form-label">Título</label>
                </div>
                <div className="form-floating mb-3" >
                  <textarea className="form-control" name="description" id="descriptionBlog" rows="3" onChange={handleChange} value={formData.description || ''}></textarea>
                  <label htmlFor="descriptionBlog" className="form-label">Descripción</label>
                </div>
                <div className="form-floating mb-3" >
                  <input className="form-control" name="imageBlog" id="imageBlog" onChange={handleChange} value={formData.img || ''} />
                  <label htmlFor="imageBlog" className="form-label">URL de la Imagen</label>
                  <img src={img}></img>
                </div>
                <div className="form-floating mb-3" >
                  <input className="form-control" name="cat" id="categoryBlog" onChange={handleChange} value={formData.cat || ''} />
                  <label htmlFor="categoryBlog" className="form-label">Categoría</label>
                </div>
                <div className="form-floating mb-3" >
                  <input type="date" className="form-control" name="fecha_publica" id="publishDateBlog" onChange={handleChange} value={formData.fecha_publica || ''} />
                  <label htmlFor="publishDateBlog" className="form-label">Fecha de Publicación</label>
                </div>
                <div className="form-floating mb-3" >
                  <input className="form-control" name="author" id="authorBlog" onChange={handleChange} value={formData.author || ''} />
                  <label htmlFor="authorBlog" className="form-label">Autor</label>
                </div>
                <div className="form-floating mb-3" >
                  <input className="form-control" name="url" id="urlBlog" onChange={handleChange} value={formData.url || ''} />
                  <label htmlFor="urlBlog" className="form-label">URL</label>
                </div>
            <div className="form-floating mb-3" >
            <ReactQuill className={styles.textRiq} name="detalles" id="detalles" onChange={handleTAChange} />
            </div>
              </>
            );
          
      default:
        return <p>Seleccione una opción para continuar</p>;
    }
  };

  if(type){
  return (
    <div className="col-sm-6">
    <form className="form-floating" onSubmit={handleSubmit}>
      {renderInputs()}
      <button type="submit" className="btn btn-primary" disabled={isLoading}>
        {isLoading ? 'Enviando...' : 'Enviar'}
      </button>
    </form>
    </div>
  );
  }

  return <p>Seleccione una opción para continuar</p>;
};

export default ApiAdmin;
