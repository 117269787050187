// src/contexts/LoadingContext.js

import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext();

export const useLoading = () => useContext(LoadingContext);

export const LoadingProvider = ({ children }) => {
  const [isLoadingx, setLoadingx] = useState(true);

  return (
    <LoadingContext.Provider value={{ isLoadingx, setLoadingx }}>
      {children}
    </LoadingContext.Provider>
  );
};