import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import LoadingComponent from '../loadingComponent/LoadingComponent.js';
import { useAuth } from '../login/AuthContext.js';
import { useNavigate } from 'react-router-dom';

function TareasProyectos(slug,{darkMode }) {
  const [data, setData] = useState(null);
  const [estados, setEstados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [tareaSeleccionada, setTareaSeleccionada] = useState(null);
  const [listaAbierta, setListaAbierta] = useState(null);
  const endpoint = 'getTareas/' + slug.slug;

  const {authData,logout} = useAuth();
  const navigate = useNavigate();

  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [registroSeleccionado, setRegistroSeleccionado] = useState(null);
  const [loadingx, setLoadingx] = useState(false);
  const [openAdd, setopenAdd] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [shouldReload , setShouldReload] = useState('init');

  const token = authData ? authData.token:null;
  const user_id = authData ? authData.user_id.toString():null;
  const rolUser = authData ? authData.rol:null;

  //console.log(location.pathname)  
  useEffect(() => {
    if (shouldReload === 'init' || shouldReload === 'reload'|| shouldReload === 'reloadEdit' || shouldReload === 'reloadDelete') {
      if(rolUser === 'admin_javi'){
        setisAdmin(true);
      }
      setShouldReload('noReload');
      setopenAdd(false)
      setModalVisible(false);
      setModalEditar(false);
      setModalEliminar(false);
      setLoading(true);
      const dataToSend = { user_id };
      fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(dataToSend)
        }).then(async (response) => {
          const data = await response.json();
          console.log(data)
          if(response.status === 403){
            logout();
            navigate('/j/login');
            return;
          }
          if (!response.ok) {
            throw new Error(data.message || 'Error en la solicitud');
          }
          setData(data);
          setEstados([...new Set(data.map((tarea) => tarea.Estado))]);
          setLoading(false);
        }).catch(error => {
          console.error("Error en fetchData: ", error);
          setError(error.message);
          setLoading(false);
          //navigate('/j/notFound');
          throw error;
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpoint, JSON.stringify(),shouldReload]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    const dataForm = new FormData(e.target);
    const type = 'tarea';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      Estado: parseInt(dataForm.get("Estado")),
      fechaEstimada: dataForm.get("fechaEstimada"),
      Descripcion: dataForm.get("Descripcion"),
      addCalendar: dataForm.get("addCalendar") === 'on' ? 1:0,
      titulo: dataForm.get("titulo"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.Estado === '' || dataToSend.fechaEstimada === '' || dataToSend.Descripcion === '' || dataToSend.titulo === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/crearProyect`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reload');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    //setLoading(true);
    const dataForm = new FormData(e.target);
    const type = 'tarea';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      Estado: parseInt(dataForm.get("Estado")),
      fechaEstimada: dataForm.get("fechaEstimada"),
      Descripcion: dataForm.get("Descripcion"),
      addCalendar: dataForm.get("addCalendar") === 'on' ? 1:0,
      titulo: dataForm.get("titulo"),
      user_id,
      token,
      type,
      idProyecto
    };
    if(dataToSend.Estado === '' || dataToSend.fechaEstimada === '' || dataToSend.Descripcion === '' || dataToSend.titulo === ''){
      alert('Debes completar todos los campos');
      return;
    }
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/editarProyect/${tareaSeleccionada}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadEdit');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  const handleDelete = async () => {
    //setLoading(true);
    const type = 'history';
    const idProyecto = parseInt(slug.slug);
    const dataToSend = {
      user_id,
      token,
      type,
      idProyecto
    };
    //console.log(dataToSend);

    setLoadingx(true);
    fetch(`https://tupanoramaurbano.cl/api/wp/v2/javi/eliminarProyect/${tareaSeleccionada}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      }).then(async (response) => {
        //console.log(data)
        if(response.status === 403){
          logout();
          navigate('/j/login');
          return;
        }
        if (!response.ok) {
          throw new Error(data.message || 'Error en la solicitud');
        }
       
        setLoadingx(false);
        setShouldReload('reloadDelete');

      }).catch(error => {
        console.error("Error en fetchData: ", error);
        setError(error.message);
        setLoadingx(false);
        //navigate('/j/notFound');
        throw error;
      });
  }

  //console.log(rolUser)

  const renderButtonAddAdmin = () => {
    if(isAdmin){
      return (
        <div className="container">
          <div className="row">
            <button className="btn btn-primary" onClick={() => setopenAdd(!openAdd)}>
              <i className="bi bi-plus-circle"></i> Agregar Tarea</button>
          </div>
          <form className="row g-3 mt-3" style={{display: openAdd ? '':'none'}} onSubmit={handleSubmit}>
            <div className="col-md-12">
              <label htmlFor="titulo" className="form-label">Titulo</label>
              <input type="text" className="form-control" id="titulo" name="titulo" placeholder="Titulo Tarea" />
            </div>
          <div className="col-md-6">
              <label htmlFor="Estado" className="form-label">Tipo</label>
              <select className="form-control" id="Estado" name="Estado" placeholder="Fecha de la actividad" >
                <option value="1">Pendientes</option>
                <option value="2">Listas</option>
                <option value="3">En Proceso</option>
                <option value="4">Descartadas</option>
                <option value="5">En Analisis</option>
                <option value="6">Otras</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="fechaEstimada" className="form-label">Fecha Limite</label>
              <input type="datetime-local" className="form-control" id="fechaEstimada" name="fechaEstimada" placeholder="Fecha Limite" />
            </div>
            <div className="col-md-12">
              <label htmlFor="Descripcion" className="form-label">Descripción</label>
              <textarea className="form-control" id="Descripcion" name="Descripcion" placeholder="Descripcion Tarea" >
              </textarea>
            </div>
            <div className="col-12">
              <input type="checkbox" className="form-check-input" id="addCalendar" name="addCalendar"/>
              <label className="form-check-label" htmlFor="addCalendar">Añadir a Calendario</label>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary" disabled={loadingx}>
               {loadingx ? 'Enviando...' : 'Registrar'}
              </button>            
              </div>
          </form>
        </div>
      )
    }
  }




  function openModal(id) {
    setRegistroSeleccionado(id)
    setTareaSeleccionada(id);
    setModalVisible(true);
  }

  function closeModal() {
    setModalVisible(false);
  }

  function openModalEdit(id) {
    console.log('Editar: ', id);
    setModalEditar(true);
    setModalEliminar(false);
    setTareaSeleccionada(id);
    setModalVisible(false);
  }

  function closeModalEdit(){
    setModalEditar(false);
  }

  function openModalDelete(id) {
    console.log('Editar: ', id);
    setModalEditar(false);
    setModalEliminar(true);
    setTareaSeleccionada(id);
    setModalVisible(false);
  }

  const toggleEstado = (estado) => {
    setListaAbierta(listaAbierta === estado ? null : estado);
  };

  const classTitle = (estado) => {
    switch (estado) {
      case 'Pendientes':
        return 'alert-warning';
      case 'En Proceso':
        return 'alert-warning';
      case 'Listas':
        return 'alert-success';
      default:
        return 'alert-info'; // Una clase por defecto para otros estados
    }
  };

  function calcularTiempoRestante(fechaEstimada) {
    const ahora = new Date();
    const estimada = new Date(fechaEstimada);
    const diferencia = estimada - ahora; // Diferencia en milisegundos
  
    if (diferencia < 0) {
      return 'Tiempo vencido';
    }
  
    // Calcular días, horas y minutos restantes
    const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
    const horas = Math.floor((diferencia / (1000 * 60 * 60)) % 24);
    const minutos = Math.floor((diferencia / 1000 / 60) % 60);
  
    return `${dias} días, ${horas} horas, ${minutos} minutos restantes`;
  }

  const generarTablasPorEstado = () => {
    if (data && data.length === 0) {
      return (
        <div className="alert alert-info" role="alert">
          No hay registros de tareas.
        </div>
      );
    }
    if (data && estados) {
      return estados.map((estado) => {
        const tareasPorEstado = data
          .filter((tarea) => tarea.Estado === estado)
          .sort((a, b) => new Date(a.fechaEstimada) - new Date(b.fechaEstimada));
        return (
          <div key={estado} className='mb-3'>
            <div className=''>
              <button
                className={`btn btn-toggle d-flex justify-content-between align-items-center w-100 ${classTitle(estado)}`}
                style={{ textAlign: 'left' }}  // Alinea el texto a la izquierda
                onClick={() => toggleEstado(estado)}
              >
                <h6>Tareas {estado}</h6>
                <i className={`bi bi-caret-down-square-fill `}></i>
              </button>
            </div>

            <ul className={`list-group ${listaAbierta === estado ? 'd-block' : 'd-none'}`}>
              {tareasPorEstado.map((tarea, index) => (
                <li key={index} className={` ${tarea.id == registroSeleccionado ? 'bg-warning':''} ${tarea.Estado === 'Listas' ? 'tareaLista': 'cursor-pointer'} ${calcularTiempoRestante(tarea.fechaEstimada) ===  'Tiempo vencido' && tarea.Estado !== 'Listas' ? 'tareaVencida':'' } list-group-item list-group-item-action `} onClick={() => openModal(tarea.id)}>
                  <div className="d-flex w-100 justify-content-between">
                    <h6 className="mb-1">{tarea.titulo}</h6>
                    <p className="mb-1 fw-bold"><i className="bi bi-hourglass-split"></i> {format(tarea.fechaEstimada, "dd/MM/yy HH:mm", { locale: es })}</p>
                   </div>
                  <small className={`${tarea.Estado === 'Listas' ? 'd-none':'d-block'}`}>Restante: {calcularTiempoRestante(tarea.fechaEstimada)}</small>
                  <small className="mb-1 text-muted"><i className="bi bi-clock-history "></i> {format(tarea.Actualización, "dd/MM HH:mm", { locale: es })} hrs.</small>  
                  <button className="btn btn-primary col-12" onClick={() => openModal(tarea.id)}>Ver Tarea</button>

                </li>
              ))}
            </ul>
          </div>
        );
      });
    }
    return null;
  };


  const tarea = data ? data.find((tarea) => tarea.id === tareaSeleccionada) : null;

  if (modalVisible) {
    return (
      <div className="modalProyect mb-5" style={{ display: 'block' }}>
        
        <div className={`col-12 col-md-6`}>
        
          <div className={`${tarea.Estado === 'Listas' ? 'tareaLista': ''} ${calcularTiempoRestante(tarea.fechaEstimada) ===  'Tiempo vencido' && tarea.Estado !== 'Listas' ? 'tareaVencida':'' } modal-content col-12 col-md-6`}>
              <span className="close" onClick={closeModal}>&times;</span>
              <h5 className="modal-title">Detalle de Tarea</h5>
              {tarea && (
                <div className={`mb-1`}>
                   <p className='mb-1'><strong>Actividad:</strong> {tarea.titulo}</p>
                   <p className='mb-1'><strong>Actualización:</strong> {format(tarea.Actualización, "dd MMMM yyyy HH:mm", { locale: es })} hrs.</p>
                   <p className='mb-1'><strong>Fecha Límite:</strong> {format(tarea.fechaEstimada, "EEEE dd MMMM yyyy HH:mm", { locale: es })} hrs.</p>
                   <p className='mb-1'><strong>Descripción:</strong> {tarea.Descripcion}</p>
                   <p className='mb-1'><strong>Estado:</strong> {tarea.Estado}</p>
                  <p className={`${tarea.Estado === 'Listas' ? 'd-none':''} mb-1`}><strong>Restante:</strong>  {calcularTiempoRestante(tarea.fechaEstimada)}</p>

                  {/* Agrega aquí más detalles según necesites */}
                </div>
              )}
          </div>
          {tarea && isAdmin && (
          <>
          <div className="modal-footer">
            <button className="btn btn-primary btn-sm" onClick={() => openModalEdit(tarea.id)}>Editar</button>
            {/*<<button className="btn btn-danger btn-sm" onClick={() => openModalDelete(tarea.id)}>Eliminar</button>*/}
          </div>

          </>
        )}
        </div>
      </div>
    );
  }

  if (modalEditar){
    return (
      <div className="modalProyect mb-4" style={{ display: 'block' }}>
      <div className="col-12 col-md-6">
        <div className="modal-content">
          <span className="close" onClick={closeModalEdit}>&times;</span>
          <h5>Editar Registro</h5>
          {tarea && (
            <form className="row g-3 mt-3" onSubmit={handleSaveEdit}>
            <div className="col-md-12">
              <label htmlFor="titulo" className="form-label">Titulo</label>
              <input defaultValue={tarea.titulo} type="text" className="form-control" id="titulo" name="titulo" placeholder="Titulo Tarea" />
            </div>
            <div className="col-md-6">
              <label htmlFor="Estado" className="form-label">Tipo</label>
              <select  defaultValue={tarea.idEstado} className="form-control" id="Estado" name="Estado" placeholder="Fecha de la actividad" >
                <option value="1">Pendientes</option>
                <option value="2">Listas</option>
                <option value="3">En Proceso</option>
                <option value="4">Descartadas</option>
                <option value="5">En Análisis</option>
                <option value="6">Otras</option>
              </select>
            </div>
            <div className="col-md-6">
              <label htmlFor="fechaEstimada" className="form-label">Fecha Limite</label>
              <input  defaultValue={tarea.fechaEstimada} type="datetime-local" className="form-control" id="fechaEstimada" name="fechaEstimada" placeholder="Fecha Limite" />
            </div>
            <div className="col-md-12">
              <label htmlFor="Descripcion" className="form-label">Descripción</label>
              <textarea defaultValue={tarea.Descripcion} className="form-control" id="Descripcion" name="Descripcion" placeholder="Descripcion Tarea" >
              </textarea>
            </div>
            <div className="col-12">
            <input type="checkbox" className="form-check-input" id="addCalendar" name="addCalendar" defaultChecked={tarea.addCalendar === "1"} />
            <label className="form-check-label" htmlFor="addCalendar">Añadir a Calendario</label>
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary" disabled={loadingx}>
              {loadingx ? 'Enviando...' : 'Guardar'}
              </button>            
              </div>
            </form>
          )}
        </div>
        {tarea && isAdmin && (
          <>
            <div className="modal-footer">
              {/*<button className="btn btn-danger btn-sm" onClick={() => openModalDelete(tarea.id)}>Eliminar</button>*/}
            </div>
          </>
        )}
      </div>
    </div>
    )
  }

  if (loading) return <div className="loadingLeft"><LoadingComponent darkMode={darkMode?'dark':''} text="Cargando Tareas..........." /></div>;
  if (error) return <p>Ocurrió un error: {error}</p>;
  return (
    <div className="mt-4 col-12 col-md-6">
      <h4 className=''>Tareas del Proyecto</h4>
      <p className="text-muted">Presiona una tarea para ver más detalles.</p>
      <div className="mb-3">
        {renderButtonAddAdmin()}
      </div>
      {generarTablasPorEstado()}
    </div>
  );
}

export default TareasProyectos;
